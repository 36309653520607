// @ts-ignore
import Vue from "vue";

interface IOuter {
    outerTop: boolean,
    outerBottom: boolean,
    outerRight: boolean,
    outerLeft: boolean,
}

export interface ITeleportParams {
    append: boolean, // Прикреплять или нет
    activatorRef: HTMLElement | null, // Ref элемента к которому прикреплять
    position: 'top' | 'right' | 'bottom' | 'left',
}

export default Vue.directive('teleport', {
    inserted(el: HTMLElement, binding: any) {
        if (binding.value.append && binding.value.activatorRef && binding.value.position) {
            document.body.appendChild(el);

            const elementRect = el.getBoundingClientRect();
            const activatorRect = binding.value.activatorRef.getBoundingClientRect();

            const fitsPosition = (pos: string) => {
                const testOuter = getOuterForPosition(elementRect, activatorRect, pos);
                return !(testOuter.outerTop || testOuter.outerBottom || testOuter.outerRight || testOuter.outerLeft);
            };

            let position = binding.value.position;

            if (!fitsPosition(position)) {
                const availablePositions = ['top', 'right', 'bottom', 'left'] as const;

                const oppositePositions = {
                    top: 'bottom',
                    bottom: 'top',
                    left: 'right',
                    right: 'left',
                };

                if (fitsPosition(oppositePositions[position])) {
                    position = oppositePositions[position] as typeof position;
                } else {
                    for (const pos of availablePositions) {
                        if (fitsPosition(pos)) {
                            position = pos;
                            break;
                        }
                    }
                }
            }

            const positionCalculators = {
                top: () => ({
                    top: activatorRect.top - elementRect.height,
                    left: activatorRect.left,
                }),
                bottom: () => ({
                    top: activatorRect.top + activatorRect.height,
                    left: activatorRect.left,
                }),
                left: () => ({
                    top: activatorRect.top + (activatorRect.height / 2) - (elementRect.height / 2),
                    left: activatorRect.left - elementRect.width,
                }),
                right: () => ({
                    top: activatorRect.top + (activatorRect.height / 2) - (elementRect.height / 2),
                    left: activatorRect.left + activatorRect.width,
                }),
            };

            let { top, left } = positionCalculators[position]();

            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;

            if (position === 'top' || position === 'bottom') {
                if (left + elementRect.width > viewportWidth) {
                    left = viewportWidth - elementRect.width;
                }
                if (left < 0) left = 0;
            }

            if (position === 'left' || position === 'right') {
                if (top + elementRect.height > viewportHeight) {
                    top = viewportHeight - elementRect.height;
                }
                if (top < 0) top = 0;
            }

            el.style.position = 'fixed';
            el.style.zIndex = '1001';
            el.style.inset = `${Math.max(0, top)}px auto auto ${Math.max(0, left)}px`;
        }
    },
    unbind(el: HTMLElement) {
        if (el && el.parentNode) {
            el.parentNode.removeChild(el);
        }
    },
});

const getOuterForPosition = (elementRect: DOMRect, activatorRect: DOMRect, position: string): IOuter => {
    const testCoords = {
        top: activatorRect.top - elementRect.height,
        bottom: activatorRect.bottom,
        left: activatorRect.left - elementRect.width,
        right: activatorRect.right,
    };

    return {
        outerTop: position === 'top' ? testCoords.top < 0 : false,
        outerBottom: position === 'bottom' ? testCoords.bottom + elementRect.height > window.innerHeight : false,
        outerRight: position === 'right' ? testCoords.right + elementRect.width > window.innerWidth : false,
        outerLeft: position === 'left' ? testCoords.left < 0 : false,
    };
};
